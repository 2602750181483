#atcb-bgoverlay {
    backdrop-filter: none;
    background: linear-gradient(180deg, #193c6b 0%, rgba(25, 60, 107, 0) 100%);
}
.atcb-list {
    box-shadow: 0 4px 5px 0 rgb(0 0 0 / 7%), 0 1px 10px 0 rgb(0 0 0 / 6%), 0 2px 4px -1px rgb(0 0 0 / 20%);
}
:root {
    --font: 'ubuntu';
}
